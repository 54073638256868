<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" >

        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.provinceName" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="height">
        <el-table-column fixed label="序号" type="index" width="50" align="center"></el-table-column>
          <el-table-column fixed label="省级名称" align="center" min-width="130px" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.provinceName }}</template>
          </el-table-column>
          <el-table-column label="第一档电价(单位" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.firstGearPrice }}</template>
          </el-table-column>
          <el-table-column label="第二档电价(单位" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.secondGearPrice }}</template>
          </el-table-column>
          <el-table-column label="第三档电价(单位" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.thirdGearPrice }}</template>
          </el-table-column>
          <el-table-column label="第一档说明" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.firstGearInstruction }}</template>
          </el-table-column>
          <el-table-column label="第二档说明" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.secondGearInstruction }}</template>
          </el-table-column>
          <el-table-column label="第三档说明" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.thirdGearInstruction }}</template>
          </el-table-column>
          <el-table-column label="脱硫煤电价(元/度)" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.desulfurizationCoalPrice }}</template>
          </el-table-column>
          <el-table-column label="工商业峰时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.maxPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="工商业峰时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.maxPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="工商业峰时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.maxPriceThird }}</template>
          </el-table-column>
          <el-table-column label="工商业峰时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.maxPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="工商业峰时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.maxPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="工商业平时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.midPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="工商业平时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.midPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="工商业平时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.midPriceThird }}</template>
          </el-table-column>
          <el-table-column label="工商业平时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.midPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="工商业平时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.midPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="工商业谷时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.minPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="工商业谷时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.minPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="工商业谷时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.minPriceThird }}</template>
          </el-table-column>
          <el-table-column label="工商业谷时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.minPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="工商业谷时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.minPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="工商业尖时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.topPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="工商业尖时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.topPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="工商业尖时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.topPriceThird }}</template>
          </el-table-column>
          <el-table-column label="工商业尖时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.topPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="工商业尖时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.topPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="大工业峰时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMaxPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="大工业峰时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMaxPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="大工业峰时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMaxPriceThird }}</template>
          </el-table-column>
          <el-table-column label="大工业峰时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMaxPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="大工业峰时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMaxPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="大工业平时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMidPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="大工业平时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMidPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="大工业平时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMidPriceThird }}</template>
          </el-table-column>
          <el-table-column label="大工业平时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMidPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="大工业平时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMidPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="大工业谷时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMinPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="大工业谷时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMinPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="大工业谷时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMinPriceThird }}</template>
          </el-table-column>
          <el-table-column label="大工业谷时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMinPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="大工业谷时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryMinPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="大工业尖时段电价<1kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryTopPriceFirst }}</template>
          </el-table-column>
          <el-table-column label="大工业尖时段电价1-10kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryTopPriceSecond }}</template>
          </el-table-column>
          <el-table-column label="大工业尖时段电价35kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryTopPriceThird }}</template>
          </el-table-column>
          <el-table-column label="大工业尖时段电价110kv" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryTopPriceFourth }}</template>
          </el-table-column>
          <el-table-column label="大工业尖时段电价220kv及以上" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryTopPriceFifth }}</template>
          </el-table-column>
          <el-table-column label="户用电价pdf地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.userEpUrl }}</template>
          </el-table-column>
          <el-table-column label="工商业电价pdf地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.industryEpUrl }}</template>
          </el-table-column>
          <el-table-column label="大工业电价pdf地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.greatIndustryEpUrl }}</template>
          </el-table-column>
          <el-table-column label="电价折扣(1-100)之间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.electricityPricesRate }}</template>
          </el-table-column>

        <el-table-column fixed="right" label="操作" width="105" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <!-- <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="省级名称：" prop="siteName">
                <el-input v-model="form.provinceName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电价类型(1户用：" prop="siteName">
                <el-input v-model="form.type" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="第一档电价(单位：" prop="siteName">
                <el-input v-model="form.firstGearPrice" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="第二档电价(单位：" prop="siteName">
                <el-input v-model="form.secondGearPrice" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="第三档电价(单位：" prop="siteName">
                <el-input v-model="form.thirdGearPrice" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="第一档说明：" prop="siteName">
                <el-input v-model="form.firstGearInstruction" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="第二档说明：" prop="siteName">
                <el-input v-model="form.secondGearInstruction" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="第三档说明：" prop="siteName">
                <el-input v-model="form.thirdGearInstruction" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="脱硫煤电价(元/度)：" prop="siteName">
                <el-input v-model="form.desulfurizationCoalPrice" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业峰时段电价<1kv：" prop="siteName">
                <el-input v-model="form.maxPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业峰时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.maxPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业峰时段电价35kv：" prop="siteName">
                <el-input v-model="form.maxPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业峰时段电价110kv：" prop="siteName">
                <el-input v-model="form.maxPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业峰时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.maxPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业平时段电价<1kv：" prop="siteName">
                <el-input v-model="form.midPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业平时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.midPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业平时段电价35kv：" prop="siteName">
                <el-input v-model="form.midPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业平时段电价110kv：" prop="siteName">
                <el-input v-model="form.midPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业平时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.midPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业谷时段电价<1kv：" prop="siteName">
                <el-input v-model="form.minPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业谷时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.minPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业谷时段电价35kv：" prop="siteName">
                <el-input v-model="form.minPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业谷时段电价110kv：" prop="siteName">
                <el-input v-model="form.minPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业谷时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.minPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业尖时段电价<1kv：" prop="siteName">
                <el-input v-model="form.topPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业尖时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.topPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业尖时段电价35kv：" prop="siteName">
                <el-input v-model="form.topPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业尖时段电价110kv：" prop="siteName">
                <el-input v-model="form.topPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业尖时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.topPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业峰时段电价<1kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMaxPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业峰时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMaxPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业峰时段电价35kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMaxPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业峰时段电价110kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMaxPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业峰时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.greatIndustryMaxPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业平时段电价<1kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMidPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业平时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMidPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业平时段电价35kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMidPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业平时段电价110kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMidPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业平时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.greatIndustryMidPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业谷时段电价<1kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMinPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业谷时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMinPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业谷时段电价35kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMinPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业谷时段电价110kv：" prop="siteName">
                <el-input v-model="form.greatIndustryMinPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业谷时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.greatIndustryMinPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业尖时段电价<1kv：" prop="siteName">
                <el-input v-model="form.greatIndustryTopPriceFirst" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业尖时段电价1-10kv：" prop="siteName">
                <el-input v-model="form.greatIndustryTopPriceSecond" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业尖时段电价35kv：" prop="siteName">
                <el-input v-model="form.greatIndustryTopPriceThird" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业尖时段电价110kv：" prop="siteName">
                <el-input v-model="form.greatIndustryTopPriceFourth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业尖时段电价220kv及以上：" prop="siteName">
                <el-input v-model="form.greatIndustryTopPriceFifth" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="户用电价pdf地址：" prop="siteName">
                <el-input v-model="form.userEpUrl" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工商业电价pdf地址：" prop="siteName">
                <el-input v-model="form.industryEpUrl" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="大工业电价pdf地址：" prop="siteName">
                <el-input v-model="form.greatIndustryEpUrl" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电价折扣(1-100)之间：" prop="siteName">
                <el-input v-model="form.electricityPricesRate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          provinceName:""
        }
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0
    };
  },

  created () {
    this.getList()
  },

  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("/electricityPrice/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    handleSave () {
	    var that = this;
	  that.$http.post("/electricityPrice/save", that.form).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 that.showDialog = false
			  that.getList()
		  }else{
			  that.$notify.info({
				title: "提示",
				message: response.data.message,
				showClose: true,
			  });
		  }
      }).catch(function(error){


      })


    },

    //修改
    edit (row) {
      this.showDialog = true;
      this.labelType = 'edit'
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除当前行
//    handleDelte (id) {
//      del({ id: id }).then(res => {
//        if (res.code == 200) {
 //         this.$message.success('删除成功')
 //         this.getList()
 //       }
//      })
//    },

	// 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
           var that = this;
        that.$http.post("/electricityPrice/delete" ,{"id":id}).then(function (response) {
			if(response.data.code == 200){
			 that.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  that.getList();
			}else{
				 that.$notify.info({
					title: "提示",
					message: response.data.message,
					showClose: true,
				  });
			}

        });
      }
    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {}
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style scoped>
</style>

